<template>
  <section v-if="data_loaded && !$route.params.type && !$route.params.category" class="single-product-homepage">

    <div v-if="data_loaded">
      <h1 data-cy="microsite-title" class="text-title text-title--main" :class="{ 'text-title--no-splash': !rest_config.splash_image_color}">
        {{ get_correct_translations_data.title }}
      </h1>

      <div data-cy="micoriste-image" class="splash-product-preview" :class="{ 'splash-product-preview--no-splash': !rest_config.splash_image_color}">
        <div class="splash-product-preview__container">
          <v-lazy-image
            v-if="rest_config.splash_image_color"
            :src="`/static/backgrounds/color-splash-round-${rest_config.splash_image_color || 'blue'}.png`"
            alt="Splash color image"
            class="splash-product-preview__background"
          />
          <router-link :to="get_product_link" itemprop="link">
            <v-lazy-image
              :src="get_first_image_of_product"
              alt="Main product image"
              class="splash-product-preview__product-img"
              :class="{
                'load-animation-class': !is_safari,
                'splash-product-preview__product-img--large': !rest_config.splash_image_color
              }"
              :style="{ animation: is_safari ? 'none' : 'float_item 6s ease-in-out infinite'}"
            />
          </router-link>
        </div>
      </div>

      <router-link :to="get_product_link" itemprop="link">
        <p data-cy="microsite-goto-product-button" class="single-product-homepage__button load-animation-class">
          {{ get_correct_translations_data.to_product_button }}
        </p>
      </router-link>

      <p
        data-cy="microsite-message-top"
        v-if="get_correct_translations_data.message_top"
        class="single-couple-styles__paragraph single-couple-styles__paragraph--right load-animation-class"
      >
        {{ get_correct_translations_data.message_top }}
      </p>

      <div class="product-button-inline">
        <router-link :to="get_product_link" itemprop="link">
          <p
            data-cy="microsite-variants-button"
            class="single-product-homepage__button load-animation-class"
          >
            {{ get_correct_translations_data.to_product_or_variants }}
          </p>
        </router-link>

        <router-link  data-cy="microsite-image-middle" :to="get_product_link" itemprop="link">
          <v-lazy-image
            :src="get_correct_image(0)"
            :src-placeholder="get_smaller_image_version(0)"
            alt="Main product image"
            class="product-button-inline__product-img load-animation-class"
          />
        </router-link>
      </div>

      <p
        data-cy="microsite-message-middle"
        v-if="get_correct_translations_data.message_middle"
        class="single-couple-styles__paragraph single-couple-styles__paragraph--left load-animation-class"
      >
        {{ get_correct_translations_data.message_middle }}
      </p>

      <router-link data-cy="microsite-image-bottom" :to="get_product_link" itemprop="link">
        <v-lazy-image
          :src="get_correct_image(1)"
          :src-placeholder="get_smaller_image_version(1)"
          alt="Main product image"
          class="single-product-homepage__product-img load-animation-class"
        />
      </router-link>
      <router-link :to="get_product_link" itemprop="link">
        <p data-cy="microsite-goto-product-bottom" class="single-product-homepage__button single-product-homepage__button--margin-top load-animation-class">
          {{ get_correct_translations_data.to_product_button }}
        </p>
      </router-link>

      <p
        data-cy="microsite-message-bottom"
        v-if="get_correct_translations_data.message_bottom"
        class="single-couple-styles__paragraph single-couple-styles__paragraph--center load-animation-class"
      >
        {{ get_correct_translations_data.message_bottom }}
      </p>

      <div class="full-screen-checkout">
        <p class="single-couple-styles__paragraph full-screen-checkout__title">
          {{ get_correct_translations_data.buy_now }}
        </p>
        <div class="full-screen-checkout__inner">
          <v-lazy-image
            :src="buy_now_img"
            :src-placeholder="default_image_src"
            alt="product image"
            class="full-screen-checkout__background"
          />
          <Checkout
            :product_data="correct_product"
            :index="0"
            @user_changed_variation="change_buy_now_img"
          />
        </div>
      </div>

      <!-- TODO Add customer review configuration -->
      <!-- <customer_reviews
        :product_id="correct_product.id"
        :fullscreen="true"
      /> -->

      <div v-if="rest_config.brand_banner" class="single-product-homepage__logo-wrapper load-animation-class">
        <a
          :href="create_main_page_url"
          class="link"
          itemprop="link"
          target="_blank"
        >
          <v-lazy-image
            :src="get_banner_image"
            :src-placeholder="default_image_src"
            alt="Brand banner"
          />
        </a>
        <p>
          {{ translations.main_page_link_message }}
        </p>
        <a
          :href="create_main_page_url"
          class="link"
          itemprop="link"
          target="_blank"
        >
          <p class="single-product-homepage__button">
            {{ translations.main_page_link_button }}
          </p>
        </a>
      </div>
    </div>
  </section>
  <div v-else-if="data_loaded && !correct_product.id" class="entity-missing">
    <missing_product />
    <h3>{{ translations.products_missing || "The product you are looking for has not been found." }}</h3>
  </div>
  <router-view v-else-if="$route.params.type !== home"/>
  <div v-else class="webpage__loading">
    <Loader class="webpage__loading-content" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import VLazyImage from "v-lazy-image/v2"
import Loader from "@/applications/Shared/components/loader/index.vue"
import create_correct_product_url from "../../methods/create_correct_product_url"
import find_image_by_id from "../../methods/find_image_by_id"
import { home, image_dimension_names } from "../../../../data/other_constants"
import { default_image_src, SHARED_STORE } from "../../../Shared/constants/other"
import default_page_type_translations from "../../../Admin/constants/empty_objects/default_page_type_translations"
import { CLIENT_STORE } from "../../constants/other"
import { ADD_PAGE_TYPE_TRANSLATIONS, FETCH_PRODUCTS_BY_IDS } from "../../stores/Client/constants"
import { fetch_page_type_data } from "../../constants/endpoints"
import get_correct_first_image from '../../../Shared/methods/get_correct_first_image'
import get_image_src from '../../../Shared/methods/get_image_src'

const Checkout = () => import("../../../Shared/components/utils/checkout")
const missing_product =  () => import("../../../Shared/components/icon_components/missing-product.vue")
// const customer_reviews = () => import("../utils/customer-reviews/customer_reviews")

export default {
  components: {
    // customer_reviews,
    Checkout,
    Loader,
    VLazyImage,
    missing_product
  },
  data() {
    return {
      is_safari: false,
      buy_now_img: default_image_src,
      data_loaded: false,
      default_image_src,
      home
    }
  },
  computed: {
    ...mapState(SHARED_STORE, [
      "is_mobile_resolution",
      "store_id"
    ]),
    ...mapState(CLIENT_STORE, [
      "products",
      "translations",
      "selected_entity",
      "collections",
      "selected_language",
      "rest_config",
      "single_page_translations",
      "user_is_using_fast_connection"
    ]),
    get_banner_image() {
      const thumb_image = this.rest_config.brand_banner?.thumb_url
      const image = this.rest_config.brand_banner?.image_url

      return (this.is_mobile_resolution || !this.user_is_using_fast_connection) ? thumb_image : image ||
        this.rest_config.brand_banner ||
        default_image_src
    },
    correct_product() {
      return this.products.find(({ handle }) => handle === this.selected_entity) || {}
    },
    get_product_link() {
      return create_correct_product_url(this.correct_product)
    },
    get_correct_translations_data() {
      return this.single_page_translations[this.selected_language] ||
        default_page_type_translations["single_product_homepages"]
    },
    create_main_page_url() {
      return `${window.location.origin}/${this.store_id}`
    },
    get_first_image_of_product() {
      return get_correct_first_image(
        this.correct_product, 
        this.is_mobile_resolution ? image_dimension_names.tablet : image_dimension_names.desktop
      )
    }
  },
  async mounted() {
    const [_, { data }] = await Promise.all([
      this.fetch_products({ ids: [this.selected_entity] }),
      fetch_page_type_data({ page_id: this.selected_entity, page_type: "single" })
    ])
    this.update_translations([data, "single"])

    this.data_loaded = true
    this.is_safari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1
    this.buy_now_img = this.get_first_image_of_product
  },
  methods: {
    ...mapActions(CLIENT_STORE, {
      fetch_products: FETCH_PRODUCTS_BY_IDS
    }),
    ...mapMutations(CLIENT_STORE, {
      update_translations: ADD_PAGE_TYPE_TRANSLATIONS
    }),
    get_image_by_index(index, images) {
      return images[index] || images[0] || { src: default_image_src }
    },
    decide_visibility(is_element_visible, variable_name) {
      this[variable_name] = is_element_visible
    },
    get_correct_image(index) {
      return get_image_src(
        this.get_image_by_index(index, this.correct_product.images),
        this.is_mobile_resolution ? image_dimension_names.tablet : image_dimension_names.desktop
      )
    },
    get_smaller_image_version(index) {
      return get_image_src(this.get_image_by_index(index, this.correct_product.images))
    },
    change_buy_now_img({ image_id }) {
      this.buy_now_img = get_image_src(
        find_image_by_id(this.correct_product.images, image_id),
        this.is_mobile_resolution ? image_dimension_names.tablet : image_dimension_names.desktop
      )
    }
  },
}
</script>

<style lang="scss">
@use "../../../../styles/_global-constants" as *;
@use "../../../../styles/load_animation.scss";
@use "../../../../styles/float_animation.scss";
@use "../../../../styles/missing_entity.scss";
@use "../../../../styles/single_couple_page_styles.scss";
@use "../../../../styles/title.scss";

.single-product-homepage {
  text-align: center;

  @media (max-width: $tablet) {
    margin-top: 50px;
  }

  &__logo-wrapper {
    width: 50%;
    padding: 50px 0;
    margin: 150px auto;
    border-top: 1px solid $pure-black;
    border-bottom: 1px solid $pure-black;

    p {
      margin: 10px 0 30px;
      font-size: 20px;
    }

    img {
      max-width: 400px;
      max-height: 400px;
      width: 100%;
    }

    .single-product-homepage__button {
      margin-bottom: 0;
    }

    @media (max-width: $tablet) {
      width: 70%;
    }

    @media (max-width: $tablet--small) {
      width: 100%;
    }
  }

  &__button {
    display: inline-block;
    padding: 15px 20px;
    margin: 0 auto;
    color: $pure-white;
    font-size: $font-size--large;
    background-color: $green-color;
    border-radius: 3px;
    box-shadow: 0px 0px 20px 0px $green-color;
    transition: 250ms ease-out;
    border-radius: $border-radius;
    font-weight: 300;

    &:hover {
      box-shadow: 0px 0px 12px -2px $green-color;
      transform: perspective(400px) translateZ(-60px);
    }

    &--margin {
      &-top {
        margin-top: 40px;
      }
    }
  }

  &__banners {
    margin: 140px 0 0;
  }

  &__product-img {
    display: block;
    max-width: 50%;
    margin: 140px auto 0;

    @media (max-width: $tablet) {
      max-width: 300px;
      max-height: 300px;
    }
  }
}

.text-title--no-splash {
  margin: 100px auto 0px;
}

.splash-product-preview {
  position: relative;
  margin: 150px auto;

  @media (max-width: $tablet--small) {
    margin: 50px auto;
  }

  &--no-splash {
    margin: 0 auto;
  }

  &__container {
    position: relative;
    min-width: 500px;
    min-height: 500px;

    @media (max-width: 500px) {
      min-width: 90%;
      min-height: unset;
      padding-top: 100%;
    }
  }

  &__background {
    position: absolute;
    display: block;
    width: 100%;
    top: 50%;
    left: 50%;
    max-width: 800px;
    max-height: 800px;
    z-index: -1;
    transform: translate(-50%, -50%);

    @media (max-width: $tablet--small) {
      width: 180%;
    }
  }

  &__product-img {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 60%;
    max-height: 60%;
    transform: translate(-50%, -50%);
    filter: drop-shadow(0 0 15px $pure-black);

    &--large {
      max-width: 90%;
      max-height: 80%;
    }

    @media (max-width: $tablet) {
      max-width: 80%;
      max-height: 80%;
    }
  }
}

.full-screen-checkout {
  margin: 150px auto;

  &__inner {
    position: relative;
    padding: 50px 0;
    margin: 2px auto 0;
  }

  &__title {
    width: 100%;
    font-size: 28px;
    text-align: center;
    box-sizing: border-box;
    margin: 0;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.4;
    filter: blur(8px);
    opacity: 0.2;
    object-fit: cover;
  }

  .checkout__wrapper {
    margin: auto;
    width: 100%;
    max-width: 500px;

    .checkout__price {
      font-size: 30px;
      text-align: center;
      color: var(--font_heavy) !important;
    }

    .checkout__title {
      display: none;
    }

    .checkout__free-shipping {
      display: none;
    }

    .checkout__desc {
      display: none;
    }

    .amount-adjuster {
      display: none;
    }

    .inline-block-element {
      display: block;
      width: 100%;
    }
  }
}

.product-button-inline {
  margin-top: 250px;
  text-align: right;

  .single-product-homepage__button {
    display: inline-block;
    max-width: 30%;
    margin-right: 19%;
    box-sizing: border-box;
    vertical-align: middle;
    text-align: center;

    @media (max-width: $tablet--small) {
      display: block;
      width: 100%;
      max-width: 100%;
      margin: 0 auto 90px;
    }
  }

  &__product-img {
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
    vertical-align: middle;
    transform: rotate(20deg);

    @media (max-width: $tablet--small) {
      display: block;
      width: 100%;
    }
  }
}
</style>
